/* VideoComponent.css */
.video {
  position: relative;
  width: 100%;
  height: calc(100svh - 64px);
}

.video video {
  width: 100vw !important;
  height: 100%;
  object-fit: cover;
}

.controls {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.volume-control,
.mute-control {
  margin: 0 5px;
}

.mute-control {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

.volume-control {
  cursor: pointer;
}
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

